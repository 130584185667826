import { soulmateInstance } from 'src/api/axiosInstance';

// TODO: These will eventually be all replaced with an autogenerated API client.
export interface SoulmateRequest {
  tikTok: TikTokData;
  spotify?: SpotifyData;
  twitter?: TwitterData;
}

export interface TikTokData {
  accessToken: string;
  refreshToken: string;
  expiresAt: number;
}

export interface SpotifyData {
  accessToken: string;
  refreshToken: string;
  expiresAt: number;
}

export interface TwitterData {
  accessToken: string;
  expiresAt: number;
}

export interface SoulmateResponse {
  soulmateId: string;
}

export interface SoulmateProgress {
  percentageComplete: number;
  message: string;
  status: SoulmateStatus;
}

export enum SoulmateStatus {
  InProgress = 'InProgress',
  Failed = 'Failed',
  Completed = 'Completed',
}

export interface SoulmateDetails {
  imageUrl: string;
  songUrl?: string;
  name: string;
  rarity: 'Common' | 'Rare' | 'Epic' | 'Unhinged';
  catchphrase: string;
  attributes: Attribute[];
}

export interface Attribute {
  key: string;
  text: string;
  level?: number;
}

export const soulmateApi = {
  async createSoulmate(params: SoulmateRequest) {
    return soulmateInstance.post<SoulmateResponse>('/soulmates', params);
  },

  async getSoulmateProgress({ soulmateId }: { soulmateId: string }) {
    return soulmateInstance.get<SoulmateProgress>(`/soulmates/${soulmateId}/progress`);
  },

  async getSoulmate({ soulmateId }: { soulmateId: string }) {
    return soulmateInstance.get<SoulmateDetails>(`/soulmates/${soulmateId}`);
  },
};
