import { useParams } from 'react-router-dom';
import { styled } from 'styled-components';

import { useGetSoulmateQuery } from 'src/api/soulmate/query';
import { ChatDialog } from 'src/components/dialogs/ChatDialog/ChatDialog';
import { ConnectSocialsDialog } from 'src/components/dialogs/ConnectSocialsDialog/ConnectSocialsDialog';
import { DiscoverDialog } from 'src/components/dialogs/DiscoverDialog/DiscoverDialog';
import { MemeifyDialog } from 'src/components/dialogs/MemeifyDialog/MemeifyDialog';
import { ShareDialog } from 'src/components/dialogs/ShareDialog/ShareDialog';
import { Button } from 'src/elements/Button';
import { Svg } from 'src/elements/Svg';
import { useDialog } from 'src/utils/hooks/useDialog';

const Container = styled.aside`
  display: flex;
  width: 160px;

  @media only screen and (max-width: 1032px) {
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 64px 0;
  width: 100%;

  @media only screen and (max-width: 1032px) {
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    padding: 16px 8px;
    width: 100%;
  }
`;

export const CardActions = () => {
  const { handle = '' } = useParams();

  // Check if the handle starts with '@'.
  const soulmateId = handle.substring(1);

  const { data: soulmate } = useGetSoulmateQuery({
    soulmateId,
  });

  const showShareDialog = useDialog(ShareDialog);
  const showBeautifyDialog = useDialog(ConnectSocialsDialog);
  const showMemeifyDialog = useDialog(MemeifyDialog);
  const showDiscoverDialog = useDialog(DiscoverDialog);
  const showChatDialog = useDialog(ChatDialog);

  return (
    <Container>
      {soulmate && (
        <Content>
          <Button $size="large" $variant="secondary" $round onClick={() => showShareDialog({ soulmate })}>
            <Svg name="share" />
          </Button>

          <Button
            $size="large"
            $variant="secondary"
            $round
            onClick={() => showBeautifyDialog({ onError: console.log })}
          >
            <Svg name="spark" />
          </Button>

          <Button $size="large" $variant="secondary" $round onClick={() => showMemeifyDialog({})}>
            <Svg name="memeify" />
          </Button>

          <Button $size="large" $variant="secondary" $round onClick={() => showDiscoverDialog({})}>
            <Svg name="discover" />
          </Button>

          <Button $size="large" $variant="secondary" $round onClick={() => showChatDialog({})}>
            <Svg name="chat" />
          </Button>
        </Content>
      )}
    </Container>
  );
};
